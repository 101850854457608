import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import AWS from "aws-sdk";
import SC from "./image-list.styles";

const ImageList: React.FC<{
  image_src: string;
  load: boolean;
}> = ({ image_src }) => {
  const [images, setImages] = useState<string[]>([]);
  const [imageError, setImageError] = useState(false);
  const [imagesLoading, setimagesLoading] = useState(true);

  useEffect(() => {
    const fetchImage = async () => {
      let formattedImgeSrc = image_src.replace(",", ", ");
      const s3 = new AWS.S3({
        accessKeyId: "AKIA6QLOECYDQGP4X34M",
        secretAccessKey: "WKmcYy9yJECjRSI+0d2i5D5h+MiiPZdS4oxSg7Zm",
        region: "af-south-1",
      });

      const params = {
        Bucket: "rtcs-gm-images",
        Key: `Africa360View/${formattedImgeSrc}.jpg`,
      };

      try {
        const url = s3.getSignedUrl("getObject", { ...params, Expires: 60 }); // URL valid for 60 seconds
        setImages([url]);
      } catch (error) {
        console.error("Error fetching image URL:", error);
      }
    };
    fetchImage();
  }, [image_src]);

  const onLoadImage = () => {
    setimagesLoading(false);
  };

  const handleImageError = () => {
    setImageError(true);
    setimagesLoading(false);
  };

  return (images && images.length < 1) || imageError ? (
    <>
      <ProgressIndicator open={imagesLoading} />
      <SC.MainImage rowHeight={100} gap={1} cols={4}>
        <SC.GridListTile cols={4} rows={3}>
          <SC.ImagePlaceholder />
        </SC.GridListTile>
      </SC.MainImage>
    </>
  ) : (
    <>
      <SC.Box>
        <img
          alt="img"
          src={images[0]}
          width="100%"
          height="100%"
          onLoad={onLoadImage}
          onError={handleImageError}
          style={{ objectFit: "fill" }}
        />
      </SC.Box>
      <ProgressIndicator open={imagesLoading} />
    </>
  );
};

export default ImageList;
