// @ts-nocheck
/* eslint-disable no-alert */
// Dependencies
import React, { useEffect, useMemo } from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Order_By,
  Query_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnArgs,
  useCorporateReturnPaginatedQuery,
  useCorporateReturnFilterQuery,
} from "graphql/hasura/types-and-hooks";
import {
  getConditionsFromDefaultFilters,
  getHasUnhandledDefaultFilter,
  mergeWithDefaultConditions,
} from "pages/reports/utils";
import generatePersistentId from "../../../../utils/packageJson";

import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Components
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import {
  FilterCondition,
  TableFilter,
  TableFilterInput,
  TableFilterType,
} from "components/table-filter/table-filter.component";
import Table, {
  INITIAL_ROWS_PER_PAGE,
  TableFetchDataFunction,
} from "components/table/table.component";
import {
  ColumnsProps,
  UniversalFilterResponse,
} from "components/universal-filter/universal-filter.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

// Schema
import Alert from "components/alert/alert.component";
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import {
  columns as TableColumns,
  DEFAULT_FILTER_ACCESSOR,
  getAccessorType,
} from "./corporate-return.schema";

// Assets
import SC from "./corporate-return.styles";

export const CorporateReturn = () => {
  const [queryVariables, setQueryVariables] = React.useState<
    Query_RootRtcs_Gm_Dev_Ph_Gra_CorporateReturnArgs | undefined
  >();

  const [pageIndexResetSignal, setPageIndexResetSignal] = React.useState(false);

  const {
    data: responseData,
    loading,
    error,
  } = useCorporateReturnPaginatedQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !queryVariables,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
      aggregateParams: jsonToStringQueryVariables({
        where: queryVariables?.where,
      }),
    },
  });

  useAppErrorHandler(error);

  const data = React.useMemo(
    () => responseData?.rtcs_gm_dev_Fngra_CorporateReturn ?? [],
    [responseData],
  );

  const columns = useMemo(() => TableColumns, []);

  const universalColumns: ColumnsProps[] = React.useMemo(() => {
    return columns.map((column, i) => {
      return {
        index: i,
        label: `${column.header}`,
        value: `${column.accessorKey}`,
        type: `${getAccessorType(column.accessorKey)}`,
      };
    });
  }, [columns]);

  const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
    const columnObject = condition[Object.keys(condition)[0]];
    if (columnObject[Object.keys(columnObject)[0]] === "") {
      // eslint-disable-next-line no-param-reassign
      condition = {};
    }
    setQueryVariables((oldQueryVariables) => ({
      ...oldQueryVariables,
      where: condition,
    }));
    setPageIndexResetSignal((previousSignal) => !previousSignal);
  };

  const handlePaginatedFetchData: TableFetchDataFunction = React.useCallback(
    (params) => {
      const { pageIndex, pageSize, sortBy } = params;
      // This default column is also set in the table columns with the property
      // "sort: true"
      const defaultSortColumn = {
        id: "Returnid",
        desc: true,
      };
      // Support just for one sort column at once
      const sortByColumn = sortBy.length > 0 ? sortBy[0] : defaultSortColumn;

      setQueryVariables((oldVariables) => ({
        ...oldVariables,
        orderBy: {
          [sortByColumn.id]: sortByColumn.desc ? Order_By.Desc : Order_By.Asc,
        },
        limit: pageSize,
        offset: pageIndex * pageSize,
      }));
    },
    [],
  );

  const totalRowsCount = parseInt(
    responseData?.rtcs_gm_dev_Fngra_CorporateReturn_aggregatecm[0].value ??
      "-1",
    10,
  );

  const paginationControlled = React.useMemo(
    () => ({
      fetchData: handlePaginatedFetchData,
      loading,
      totalRowsCount,
    }),
    [totalRowsCount, loading, handlePaginatedFetchData],
  );

  const { refetch: ReportFilterFn } = useCorporateReturnFilterQuery({
    skip: true,
    context: {
      clientName: CLIENT_NAME.HASURA,
    },
  });
  const onTableFilterApply = React.useCallback(
    (conditions: FilterCondition[]) => {
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: {
          _and: conditions.length > 0 ? conditions : undefined,
        },
      }));
      setPageIndexResetSignal((previousSignal) => !previousSignal);
    },
    [],
  );

  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <ProgressIndicator open={loading} />
      <Table
        actionsOnRight={[
          "fullscreen-expand",
          "hide/show-columns",
          "filter-results",
        ]}
        title="Corporate Return"
        columns={columns}
        data={data}
        // eslint-disable-next-line no-alert
        onAction={() => alert("under construction")}
        headerPanel={<SC.Title>MDA - Gambia Revenue Authority</SC.Title>}
        leftPanel={
          <TableFilter
            onFilterApply={onTableFilterApply}
            universalFilterColumns={universalColumns}
            onUniversalFilterSubmit={onUniversalFilterer}
          />
        }
        initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
        paginationControlled={paginationControlled}
        persistenceId={generatePersistentId(
          "b0aee1ce-d9ad-4127-be26-107962e755cb",
        )}
        stickyHeader
        pageIndexResetSignal={pageIndexResetSignal}
      />
    </SC.Box>
  );
};

export default CorporateReturn;
