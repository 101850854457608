// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from "react";
import _ from "lodash";

// Types
import TablePaginationActions from "components/table-pagination-actions/table-pagination-actions.component";
import { TableToolbarAction } from "components/table-toolbar-actions/table-toolbar-actions.component";

// Assets
import { TableToolbarButtonProps } from "components/table-toolbar-button/table-toolbar-button.component";
import SC from "./table-toolbar.styles";

interface Props {
  title?: string | JSX.Element;
  toolbarButtonProps?: TableToolbarButtonProps;
  rowsCount: number;
  page: number;
  rowsPerPage: number;
  isPaginationHidden?: boolean;
  rowsPerPageOptions: number[];
  onChangePage?: (page: number) => void;
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
  actionsOnLeft?: TableToolbarAction[];
  actionsOnRight?: TableToolbarAction[];
  onAction?: (action: TableToolbarAction) => void;
  actionsStates?: Record<TableToolbarAction, boolean | undefined>;
}

const TableToolbar: React.FC<Props> = ({
  title = "",
  toolbarButtonProps,
  rowsCount,
  page,
  rowsPerPage,
  isPaginationHidden,
  rowsPerPageOptions,
  onChangePage,
  onChangeRowsPerPage,
  actionsOnLeft,
  actionsOnRight,
  onAction,
  actionsStates,
}) => {
  const handlerOnChangePage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => void = (event, newPage) => {
    onChangePage?.(newPage);
    event.stopPropagation();
  };

  const handlerOnChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void = (event) => {
    onChangeRowsPerPage?.(parseInt(event?.target.value, 10));
    event.stopPropagation();
  };

  const titleComponent = React.useMemo(
    () =>
      _.isString(title) ? (
        <SC.Title variant="subtitle1">{title}</SC.Title>
      ) : (
        title
      ),
    [title],
  );

  return (
    <SC.Toolbar disableGutters>
      <SC.TitleActionsContainer>
        <SC.TitleWrapper>{titleComponent}</SC.TitleWrapper>

        {actionsOnLeft && (
          <SC.TableToolbarActions actions={actionsOnLeft} onAction={onAction} />
        )}

        {toolbarButtonProps && (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <SC.TableToolbarButton {...toolbarButtonProps} />
        )}
      </SC.TitleActionsContainer>

      {!isPaginationHidden && (
        <SC.TablePagination
          labelRowsPerPage="Rows per page"
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={rowsCount}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlerOnChangePage}
          onRowsPerPageChange={handlerOnChangeRowsPerPage}
          ActionsComponent={(props) => (
            <TablePaginationActions
              onPageChange={handlerOnChangePage}
              page={page}
              rowsPerPage={rowsPerPage}
              count={rowsCount}
            />
          )}
        />
      )}

      {actionsOnRight && (
        <SC.TableToolbarActions
          actionsStates={actionsStates}
          actions={actionsOnRight}
          onAction={onAction}
        />
      )}
    </SC.Toolbar>
  );
};

export default TableToolbar;
