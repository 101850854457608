// @ts-nocheck
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { MOJMonthlyRegistrationsFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";

export type MOJMonthlyRegistrationsSchema = Omit<
  MOJMonthlyRegistrationsFragment,
  "__typename"
>;

// @ts-ignore
export const MOJMonthlyRegistrationsSchemaMap: Record<
  keyof MOJMonthlyRegistrationsSchema,
  "string" | "number"
> = {
  Address: "string",
  Businessname: "string",
  Businesstype: "string",
  Dateofregistration: "string",
  Extractcriteria: "string",
  Mobile: "string",
  Srntin: "string",
};

export const getAccessorType = (
  accessor:
    | keyof MOJMonthlyRegistrationsSchema
    | AccessorFn<MOJMonthlyRegistrationsSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return MOJMonthlyRegistrationsSchemaMap[accessor];
};

export const columns: TableColumn<MOJMonthlyRegistrationsSchema>[] = [
  {
    header: "Business Name",
    accessorKey: "Businessname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Business Type",
    accessorKey: "Businesstype",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Extract Criteria",
    accessorKey: "Extractcriteria",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Mobile",
    accessorKey: "Mobile",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Srn TIN",
    accessorKey: "Srntin",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Date Of Registration",
    accessorKey: "Dateofregistration",
    align: "left",
    meta: { sort: false },
    format: "date",
  },
  {
    header: "Address",
    accessorKey: "Address",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
];
