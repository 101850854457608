/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useWindowHeight } from "@react-hook/window-size";
import _ from "lodash";

// Components
import BannerNavigation from "components/banner-navigation/banner-navigation.component";

// Pages
import HomePage from "pages/home/home.component";
import MyProfilePage from "pages/my-profile/my-profile.component";
import UserManagementPage from "pages/user-management/user-management.component";
import GroupManagementPage from "pages/group-management/group-management.component";
import RoleManagementPage from "pages/role-management/role-management.component";
// import HasuraPoc from "pages/hasura-poc/hasura-poc.component";
// import IndividualPage from "pages/individual/individual.component";
// import NonIndividualPage from "pages/non-individual/non-individual.component";
// import PropertiesPage from "pages/properties/properties.component";
import ReportsMainPage from "pages/reports/main/main.component";
// import IndividualTabPage from "pages/individual-tab/main/individual-tab.component";
// import NonIndividualTabPage from "pages/non-individual-tab/main/non-individual-tab.component";
import PropertiesTabPage from "pages/properties-tab/main/main.component";
//import VisibilityPage from "pages/visibility/visibility.component";
import RevisionPage from "pages/revision/revision.component";
import CommercialResidentialTax from "pages/reports/gra/commercial-residential-tax/commercial-residential-tax.component";
import CorporateReturnExemption from "pages/reports/gra/corporate-return-exemption/corporate-return-exemption.component";
import StreetView from "pages/reports/street-view/street-view/street-view.component";
import CorporateReturn from "pages/reports/gra/corporate-return/corporate-return.component";
import CorporateReturnDirector from "pages/reports/gra/corporate-return-director/corporate-return-director.component";
import CorporateReturnLoss from "pages/reports/gra/corporate-return-loss/corporate-return-loss.component";
import CorporateReturnPartnership from "pages/reports/gra/corporate-return-partnership/corporate-return-partnership.component";
import CorporateReturnPropertyRent from "pages/reports/gra/corporate-return-property-rent/corporate-return-property-rent.component";
import CorporateReturnRentReceived from "pages/reports/gra/corporate-return-rent-received/corporate-return-rent-received.component";
import IndividualReturns from "pages/reports/gra/individual-returns/individual-returns.component";
import IndividualReturnExemptions from "pages/reports/gra/individual-return-exemptions/individual-return-exemptions.component";
import PaymentRental from "pages/reports/gra/payment-rental/payment-rental.component";
import MOJMonthlyRegistrations from "pages/reports/groc/moj/moj-monthly-registrations.component";
import TradeLicenseRegister from "pages/reports/bcc/trade-license-register/trade-license-register.component";
import PropertyRates from "pages/reports/bcc/property-rates/property-rates.component";

import {
  APP_PERMISSION,
  useAppPermissionValidator,
} from "components/app-permission-validator/app-permission-validator.component";

// Assets
import SC from "./main.styles";

const MainPage: React.FC = () => {
  const pageRef = React.useRef<HTMLDivElement>(null);
  const pageContentRef = React.useRef<HTMLDivElement>(null);

  const pageRefPosition = pageRef.current?.getBoundingClientRect();
  const pageContentPosition = pageContentRef.current?.getBoundingClientRect();

  const pagePositionY = pageRefPosition?.y ?? 0;
  const pageContentPositionY = pageContentPosition?.y ?? 0;

  const windowHeight = useWindowHeight();
  const pageHeight = windowHeight - pagePositionY;
  const pageContentHeight = windowHeight - pageContentPositionY;

  const appPermissionValidator = useAppPermissionValidator();

  interface RouteConfig {
    path: string | string[];
    element?: React.ReactNode;
    component?: React.ComponentType;
  }

  const routes: RouteConfig[] = [
    /* {
      path: "hasura-poc",
      component: HasuraPoc,
    }, */
    {
      path: "my-profile",
      component: MyProfilePage,
    },
  ];

  if (appPermissionValidator) {
    if (appPermissionValidator(APP_PERMISSION.HOME_VIEW)) {
      routes.push({
        path: "home",
        component: HomePage,
      });
    }
    // TODO: uncomment each section based on your requirements
    // if (appPermissionValidator(APP_PERMISSION.INDIVIDUALS_VIEW)) {
    //   routes.push(
    //     {
    //       path: "individuals",
    //       component: IndividualPage,
    //     },
    //     {
    //       path: "individuals/:repId/*",
    //       component: IndividualTabPage,
    //       element: <IndividualTabPage />,
    //     },
    //   );
    // }

    // if (appPermissionValidator(APP_PERMISSION.NON_INDIVIDUALS_VIEW)) {
    //   routes.push(
    //     {
    //       path: "non-individuals",
    //       component: NonIndividualPage,
    //     },
    //     {
    //       path: "non-individuals/:repId/*",
    //       component: NonIndividualTabPage,
    //     },
    //   );
    // }

    if (appPermissionValidator(APP_PERMISSION.PROPERTIES_VIEW)) {
      routes.push(
        //{
        // path: "properties",
        // component: PropertiesPage,
        //},
        {
          path: "properties/:repId/*",
          component: PropertiesTabPage,
        },
      );
    }

    if (appPermissionValidator(APP_PERMISSION.REPORTS)) {
      routes.push(
        {
          path: "reports",
          component: ReportsMainPage,
        },
        {
          path: [
            "reports/MDA",
            "reports/MDA/Africa-360-view",
            "reports/MDA/GRA",
            "reports/MDA/GROC",
          ],
          element: <Navigate to={"/reports"} />,
        },
        {
          path: [
            "reports/analytics",
            "reports/analytics/:report",
            // "reports/analytics/declared-vs-reported-income/individuals-and-non-individuals/tenant",
            // "reports/analytics/declared-vs-reported-income/individuals-and-non-individuals/tenant/:landlordTin",
            // "reports/analytics/declared-vs-reported-income/individuals-and-non-individuals/tenant/:landlordTin/:taxYear",
            // "reports/analytics/declared-vs-reported-income/individuals-and-non-individuals/landlord",
            // "reports/analytics/declared-vs-reported-income/individuals-and-non-individuals/landlord/:landlordTin",
          ],
          element: <Navigate to={"/reports"} />,
        },
        {
          path: "reports/MDA/Africa-360-view/properties",
          component: StreetView,
        },
        {
          path: "reports/MDA/GRA/commercial-residential-tax",
          component: CommercialResidentialTax,
        },
        {
          path: "reports/MDA/GRA/corporate-return",
          component: CorporateReturn,
        },
        {
          path: "reports/MDA/GRA/corporate-return-director",
          component: CorporateReturnDirector,
        },
        {
          path: "reports/MDA/GRA/corporate-return-exemption",
          component: CorporateReturnExemption,
        },
        {
          path: "reports/MDA/GRA/corporate-return-loss",
          component: CorporateReturnLoss,
        },
        {
          path: "reports/MDA/GRA/corporate-return-partnership",
          component: CorporateReturnPartnership,
        },
        {
          path: "reports/MDA/GRA/corporate-return-property-rent",
          component: CorporateReturnPropertyRent,
        },
        {
          path: "reports/MDA/GRA/corporate-return-rent-received",
          component: CorporateReturnRentReceived,
        },
        {
          path: "reports/MDA/GRA/individual-returns",
          component: IndividualReturns,
        },
        {
          path: "reports/MDA/GRA/individual-return-exemptions",
          component: IndividualReturnExemptions,
        },
        {
          path: "reports/MDA/GRA/payment-rental",
          component: PaymentRental,
        },
        {
          path: "reports/MDA/GROC/moj-monthly-registrations",
          component: MOJMonthlyRegistrations,
        },
        {
          path: "reports/MDA/BCC/trade-license-register",
          component: TradeLicenseRegister,
        },
        {
          path: "reports/MDA/BCC/property-rates",
          component: PropertyRates,
        },
        // {
        // {
        //   path: "reports/MDA/rCAPTURE/properties/:propertyId",
        //   component: RCapturePropertyDashboard,
        // },
      );
    }

    if (appPermissionValidator(APP_PERMISSION.ADMIN)) {
      routes.push(
        {
          path: "administration/user-management/users",
          component: UserManagementPage,
        },
        {
          path: "administration/user-management/groups",
          component: GroupManagementPage,
        },
        {
          path: "administration/user-management/roles",
          component: RoleManagementPage,
        },
      );

      /*if (appPermissionValidator(APP_PERMISSION.ADMIN_SET_TAX_EXEMPT)) {
        routes.push({
          path: "administration/tax-exempt-organisations",
          component: TaxExemptOrganisationPage,
        });
      } */

      /*if (appPermissionValidator(APP_PERMISSION.ADMIN_SET_VISIBILITY_LEVEL)) {
        routes.push({
          path: "administration/visibility",
          component: VisibilityPage,
        });
      } */

      routes.push({
        path: "administration/revision",
        component: RevisionPage,
      });
    }

    routes.push({
      path: "*",
      element: <>Not Found</>,
    });
  }

  return (
    <SC.Page maxWidth={false} ref={pageRef} maxHeight={pageHeight}>
      <BannerNavigation />
      <SC.PageContent
        maxWidth={false}
        ref={pageContentRef}
        maxHeight={pageContentHeight}
      >
        <Routes>
          {routes.map((route, index) =>
            Array.isArray(route.path) ? (
              route.path.map((p) => (
                <Route
                  key={`${index}-${p}`}
                  path={p}
                  element={route.element}
                  Component={route.component}
                />
              ))
            ) : (
              <Route
                key={index}
                path={route.path}
                element={route.element}
                Component={route.component}
              />
            ),
          )}
        </Routes>
      </SC.PageContent>
    </SC.Page>
  );
};

export default MainPage;
