import React from "react";

// Component
import ExpandedMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandedLessIcon from "@mui/icons-material/ExpandLess";
import LabelKeyValue from "components/label-key-value/label-key-value.component";

import { FORMAT_VALUE_OPTIONS } from "components/formatted-value/formatted-value";

// Styles
import SC from "./accordion-header.styles";

export interface Data {
  index: number;
  label: string;
  value: string;
  format?: keyof typeof FORMAT_VALUE_OPTIONS;
}

export interface Content {
  index: number;
  title: string;
  data: Data[];
}

export interface Header {
  index: number;
  label: string;
  type?: string | undefined;
}

export interface AccordionHeaderProps {
  header: Header[];
  content: Content[];
  source?: string;
  isLoading?: boolean;
}

export const AccordionHeader: React.FC<AccordionHeaderProps> = ({
  header,
  content,
  source,
  isLoading = false,
}) => {
  const ref = React.useRef(null);
  const [expand, setExpand] = React.useState(true);

  const onExpand = (expanded: boolean) => {
    setExpand(expanded);
  };

  if (isLoading) return <SC.ProgressIndicator />;

  return (
    <SC.Accordion
      ref={ref}
      square
      expanded={expand}
      elevation={0}
      onChange={(_event, expanded) => onExpand(expanded)}
    >
      <SC.AccordionSummary aria-controls="panel1c-content" id="panel1c-header">
        {header.map((item) => (
          <SC.Label
            key={item.index}
            type={item.type}
            variant="h6"
            sx={{ fontSize: "1.71rem !important" }}
          >
            {" "}
            {item.label}
          </SC.Label>
        ))}
        <SC.IconButton color="inherit" disableRipple size="small">
          {!expand ? <ExpandedMoreIcon /> : <ExpandedLessIcon />}
          <SC.LabelButtonText variant="subtitle2">
            {" "}
            {!expand ? "Expand" : "Collapse"}
          </SC.LabelButtonText>
        </SC.IconButton>
      </SC.AccordionSummary>
      <SC.Divider />
      <SC.AccordionDetails>
        <SC.Grid container spacing={2}>
          {content.map(({ index, title, data }: Content) => (
            <SC.Grid key={index} item xs>
              <SC.LabelTitle variant="subtitle2"> {title}</SC.LabelTitle>
              {data.map((item_content) => (
                <LabelKeyValue
                  key={item_content.index}
                  label={item_content.label}
                  value={item_content.value}
                  format={item_content.format}
                />
              ))}
            </SC.Grid>
          ))}
        </SC.Grid>
      </SC.AccordionDetails>
      {source && (
        <SC.Grid container justifyContent="flex-end">
          <SC.Grid item xs={4}>
            <LabelKeyValue label="Source" value={source} />
          </SC.Grid>
        </SC.Grid>
      )}
    </SC.Accordion>
  );
};

export default AccordionHeader;
