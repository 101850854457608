export default [
  {
    title: "MDA Reports",
    reports: [
      {
        title: "Africa 360 View",
        subtitles: [
          {
            label: "Properties",
            url: "MDA/Africa-360-view/properties",
          },
        ],
      },
      {
        title: "Gambia Revenue Authority",
        subtitles: [
          {
            label: "Commercial-Residential Tax",
            url: "MDA/GRA/commercial-residential-tax",
          },
          {
            label: "Corporate Return",
            url: "MDA/GRA/corporate-return",
          },
          {
            label: "Corporate Return Director",
            url: "MDA/GRA/corporate-return-director",
          },
          {
            label: "Corporate Return Exemption",
            url: "MDA/GRA/corporate-return-exemption",
          },
          {
            label: "Corporate Return Loss",
            url: "MDA/GRA/corporate-return-loss",
          },
          {
            label: "Corporate Return Partnership",
            url: "MDA/GRA/corporate-return-partnership",
          },
          {
            label: "Corporate Return Property Rent",
            url: "MDA/GRA/corporate-return-property-rent",
          },
          {
            label: "Corporate Return Rent Received",
            url: "MDA/GRA/corporate-return-rent-received",
          },
          {
            label: "Individual Returns",
            url: "MDA/GRA/individual-returns",
          },
          {
            label: "Individual Return Exemptions",
            url: "MDA/GRA/individual-return-exemptions",
          },
          {
            label: "Payment Rental",
            url: "MDA/GRA/payment-rental",
          },
        ],
      },
      {
        title: "Gambia Registry of Companies",
        subtitles: [
          {
            label: "MOJ Monthly Registrations",
            url: "MDA/GROC/moj-monthly-registrations",
          },
        ],
      },
      {
        title: "Banjul City Council",
        subtitles: [
          {
            label: "Trade License Register",
            url: "MDA/BCC/trade-license-register",
          },
          {
            label: "Property Rates",
            url: "MDA/BCC/property-rates",
          },
        ],
      },
    ],
  },
  {
    title: "Analytic Reports",
    reports: [],
  },
];
