/* eslint-disable no-alert */
// Dependencies
import React, { useEffect, useMemo } from "react";
import packageJson from "../../../../../package.json";
// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Gm_Dev_Streetview_StreetViewAnalysis_AggregateArgs,
  usePaginatedStreetViewQuery,
} from "graphql/hasura/types-and-hooks";
import { ColumnDef } from "@tanstack/react-table";
import { ServerDataToLayer } from "rView/home/utils/layer";

import useExportData from "exports/useExportData";

import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import {
  ColumnsProps,
  UniversalFilterResponse,
} from "components/universal-filter/universal-filter.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import TableFilter, {
  FilterCondition,
} from "components/table-filter/table-filter.component";
import {
  getConditionsFromDefaultFilters,
  getHasUnhandledDefaultFilter,
  mergeWithDefaultConditions,
} from "pages/reports/utils";

// Schema
import {
  propertiesTableColumns,
  IndividualRegistrationTableFilters,
  DEFAULT_FILTER_ACCESSOR,
  getAccessorType,
} from "./street-view.schema";

// Assets
import SC from "./street-view.styles";
import { INITIAL_ROWS_PER_PAGE } from "components/table/table.component";

interface IndividualRegistrationTableProps {
  displayHeader?: boolean;
  defaultFilters?: IndividualRegistrationTableFilters;
  initialRowsPerPage?: number;
  maxHeight?: number;
  noDataComponent?: JSX.Element;
  onLoaded?: () => void;
  title?: string;
}

const TABLE_NAME = "rtcs_db_FnRPT_IndividualRegistration";
const persistenceId = `${packageJson.version}-f98bcf79-e46d-4568-9253-1a1c3d8c2142`;

export const IndividualRegistrationTable: React.FC<IndividualRegistrationTableProps> =
  React.memo(({ defaultFilters, onLoaded }) => {
    const [rowsPerPageData, setRowsPerPageData] = React.useState<[]>([]);
    const [pageIndexResetSignal, setPageIndexResetSignal] =
      React.useState(false);
    const defaultFilterConditions = useMemo(
      () =>
        getConditionsFromDefaultFilters(
          defaultFilters ?? {},
          DEFAULT_FILTER_ACCESSOR,
        ),
      [defaultFilters],
    );
    const columns = useMemo(() => propertiesTableColumns, []);
    const universalColumns: ColumnsProps[] = useMemo(() => {
      return columns.map((column, i) => {
        return {
          index: i,
          label: `${column.header}`,
          value: `${column.accessorKey}`,
          type: `${getAccessorType(column.accessorKey)}`,
        };
      });
    }, [columns]);

    const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
      const columnObject = condition[Object.keys(condition)[0]];
      if (columnObject[Object.keys(columnObject)[0]] === "") {
        // eslint-disable-next-line no-param-reassign
        condition = {};
      }
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: condition,
      }));
      setPageIndexResetSignal((previousSignal) => !previousSignal);
    };
    const hasUnhandledDefaultFilter = useMemo(
      () =>
        getHasUnhandledDefaultFilter(
          defaultFilters ?? {},
          DEFAULT_FILTER_ACCESSOR,
        ),
      [defaultFilters],
    );
    const [filterConditions, setFilterConditions] = React.useState<
      FilterCondition[]
    >([] as FilterCondition[]);
    const [queryVariables, setQueryVariables] =
      React.useState<Query_RootRtcs_Gm_Dev_Streetview_StreetViewAnalysis_AggregateArgs>(
        {
          where: mergeWithDefaultConditions(
            defaultFilterConditions,
            filterConditions,
          ),
        },
      );

    const { data, loading, error } = usePaginatedStreetViewQuery({
      context: { clientName: CLIENT_NAME.HASURA },
      skip: !queryVariables || hasUnhandledDefaultFilter,
      variables: {
        params: jsonToStringQueryVariables(queryVariables ?? {}),
        aggregateParams: jsonToStringQueryVariables({
          where: queryVariables?.where,
        }),
      },
    });

    const [exportDataFn, { loading: exportLoading, error: exportError }] =
      useExportData(TABLE_NAME, queryVariables);

    useAppErrorHandler(error || exportError);

    const properties = React.useMemo(() => {
      const individual =
        data?.rtcs_gm_dev_Fnstreetview_StreetViewAnalysis ?? [];

      return individual;
    }, [data]);

    useEffect(() => {
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: mergeWithDefaultConditions(
          defaultFilterConditions,
          filterConditions,
        ),
      }));
    }, [defaultFilterConditions, filterConditions]);
    useEffect(() => {
      if (!loading) onLoaded?.();
    }, [loading, onLoaded]);

    const onTableFilterApply = React.useCallback(
      (conditions: FilterCondition[]) => {
        setQueryVariables((oldQueryVariables) => ({
          ...oldQueryVariables,
          where: {
            _and: conditions.length > 0 ? conditions : undefined,
          },
        }));
        setPageIndexResetSignal((previousSignal) => !previousSignal);
      },
      [],
    );

    return (
      <>
        <SC.SwitchViews
          LeftView={
            <SC.MapTableContainer
              actionsOnRight={[
                "fullscreen-expand",
                "hide/show-columns",
                "filter-results",
              ]}
              columns={propertiesTableColumns}
              properties={properties}
              persistenceId={persistenceId}
              paginationControlled={undefined}
              initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
              pageIndexResetSignal={pageIndexResetSignal}
              stickyHeader
              leftPanel={
                <TableFilter
                  onFilterApply={onTableFilterApply}
                  universalFilterColumns={universalColumns}
                  onUniversalFilterSubmit={onUniversalFilterer}
                />
              }
              exportData={(columns: ColumnDef<any, any>[]) =>
                exportDataFn(columns, undefined, properties)
              }
              onDataPerPageChange={(data) => {
                setRowsPerPageData(data);
              }}
            />
          }
          leftViewName="Table"
          RightView={
            <SC.MapContainer properties={ServerDataToLayer(rowsPerPageData)} />
          }
          rightViewName="Map"
        />
        <SC.ProgressIndicator open={exportLoading || loading} />
      </>
    );
  });

const StreetView: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb
        staticRoutes={[
          {
            index: 0,
            path: "/reports",
            text: "Reports",
          },
          {
            index: 1,
            path: "/reports",
            text: "MDA",
          },
          {
            index: 2,
            path: "/reports/MDA/Africa-360-view/properties",
            text: "Africa 360 View",
          },
          {
            index: 3,
            path: "/reports/MDA/Africa-360-view/properties",
            text: "Properties",
          },
        ]}
        label="Street View"
      />
      <IndividualRegistrationTable displayHeader />
    </SC.Box>
  );
};

export default StreetView;
