// @ts-nocheck
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { CorporateReturnDirectorFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";

export type CorporateReturnDirectorSchema = Omit<
  CorporateReturnDirectorFragment,
  "__typename"
>;

// @ts-ignore
export const CorporateReturnDirectorSchemaMap: Record<
  keyof CorporateReturnDirectorSchema,
  "string" | "number"
> = {
  Directorfee: "number",
  Directorname: "string",
  Directorremunerationid: "string",
  Otherallowance: "number",
  Returnid: "string",
  Salary: "number",
  Tin: "string",
  Total: "number",
};

export const getAccessorType = (
  accessor:
    | keyof CorporateReturnDirectorSchema
    | AccessorFn<CorporateReturnDirectorSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return CorporateReturnDirectorSchemaMap[accessor];
};

export const columns: TableColumn<CorporateReturnDirectorSchema>[] = [
  {
    header: "Director RemunerationID",
    accessorKey: "Directorremunerationid",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "ReturnID",
    accessorKey: "Returnid",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Director Name",
    accessorKey: "Directorname",
    align: "right",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "TIN",
    accessorKey: "Tin",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Director Fee",
    accessorKey: "Directorfee",
    align: "right",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Other Allowance",
    accessorKey: "Otherallowance",
    align: "right",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Salary",
    accessorKey: "Salary",
    align: "right",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Total",
    accessorKey: "Total",
    align: "right",
    meta: { sort: false },
    format: "string",
  },
];
