import { TableColumn } from "components/table/table.component";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import { GraIndividualReturnExemptionFragment } from "graphql/hasura/types-and-hooks";

export type IndividualReturnExemptionSchema = Omit<
  GraIndividualReturnExemptionFragment,
  "__typename"
>;

export const INDIVIDUAL_RETURN_EXEMPTION_SCHEMA_MAP: Record<
  keyof IndividualReturnExemptionSchema,
  "string" | "number" | "Date"
> = {
  Amount: "number",
  Basis: "string",
  ExemptionID: "string",
  IncomeType: "number",
  ReturnID: "number",
  TaxLiability: "number",
  TaxRate: "number",
};

export const getAccessorType = (
  accessor:
    | keyof IndividualReturnExemptionSchema
    | AccessorFn<IndividualReturnExemptionSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return INDIVIDUAL_RETURN_EXEMPTION_SCHEMA_MAP[accessor];
};

export const tableColumns: TableColumn<IndividualReturnExemptionSchema>[] = [
  {
    header: "Exemption ID",
    accessorKey: "ExemptionID",
    align: "center",
    format: "string",
  },
  {
    header: "Return ID",
    accessorKey: "ReturnID",
    align: "center",
    format: "string",
  },
  {
    header: "Income Type",
    accessorKey: "IncomeType",
    align: "right",
    format: "number",
  },
  {
    header: "Amount",
    accessorKey: "Amount",
    align: "right",
    format: "number",
  },
  {
    header: "Tax Rate",
    accessorKey: "TaxRate",
    align: "right",
    format: "number",
  },
  {
    header: "Basis",
    accessorKey: "Basis",
    align: "right",
    format: "number",
  },
  {
    header: "Tax Liability",
    accessorKey: "TaxLiability",
    align: "right",
    format: "number",
  },
];
