// Dependencies
import React from "react";

// Assets
import SC from "./filter-list-item.styles";

export interface ListItemProps {
  name: string;
  label: string;
  checked: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const ListItem: React.FC<ListItemProps> = React.memo(
  ({ name, label, checked, onClick }) => (
    <SC.ListItemButton id={name} onClick={onClick}>
      <SC.ListItemIcon>
        <SC.Checkbox
          edge="start"
          checked={checked}
          tabIndex={-1}
          disableRipple
          inputProps={{ "aria-labelledby": name }}
        />
      </SC.ListItemIcon>
      <SC.ListItemText id={name} primary={label} />
    </SC.ListItemButton>
  ),
);

export default ListItem;
