import { TableColumn } from "components/table/table.component";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import { GraCorporateReturnRentReceivedFragment } from "graphql/hasura/types-and-hooks";

export type CorporateReturnRentReceivedSchema = Omit<
  GraCorporateReturnRentReceivedFragment,
  "__typename"
>;

export const CORPORATE_RETURN_RENT_RECEIVED_SCHEMA_MAP: Record<
  keyof CorporateReturnRentReceivedSchema,
  "string" | "number" | "Date"
> = {
  Annualrent: "number",
  Annualrentpaid: "number",
  Commercialused: "number",
  Landlordname: "string",
  Landlordtin: "number",
  Propertyaddress: "string",
  Rentid: "string",
  Residentialused: "number",
  Returnid: "number",
  Telephone: "string",
  Tenancystartdate: "string",
};

export const getAccessorType = (
  accessor:
    | keyof CorporateReturnRentReceivedSchema
    | AccessorFn<CorporateReturnRentReceivedSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return CORPORATE_RETURN_RENT_RECEIVED_SCHEMA_MAP[accessor];
};

export const tableColumns: TableColumn<CorporateReturnRentReceivedSchema>[] = [
  {
    header: "Return ID",
    accessorKey: "Returnid",
    align: "center",
    format: "string",
  },
  {
    header: "Rent ID",
    accessorKey: "Rentid",
    align: "center",
    format: "string",
  },
  {
    header: "Landlord Name",
    accessorKey: "Landlordname",
    align: "left",
    format: "string",
  },
  {
    header: "Property Address",
    accessorKey: "Propertyaddress",
    align: "left",
    format: "string",
  },
  {
    header: "Landlord TIN",
    accessorKey: "Landlordtin",
    align: "center",
    format: "string",
  },
  {
    header: "Tenancy Start Date",
    accessorKey: "Tenancystartdate",
    align: "center",
    format: "date",
  },
  {
    header: "Annual Rent",
    accessorKey: "Annualrent",
    align: "right",
    format: "number",
  },
  {
    header: "Annual Rent Paid",
    accessorKey: "Annualrentpaid",
    align: "right",
    format: "number",
  },
  {
    header: "Commercial Used",
    accessorKey: "Commercialused",
    align: "right",
    format: "number",
  },
  {
    header: "Residential Used",
    accessorKey: "Residentialused",
    align: "right",
    format: "number",
  },
  {
    header: "Telephone",
    accessorKey: "Telephone",
    align: "center",
    format: "string",
  },
];
