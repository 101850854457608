import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

// Components
import TextField from "components/text-field/text-field.component";
import LandingPage from "components/landing-page/landing-page.component";
import { AlertProps } from "components/alert/alert.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

// Assets
import SC from "components/landing-page/landing-page.styles";

// GraphQL
import { useValidateLoginOtpLazyQuery } from "graphql/types-and-hooks";

import auth from "graphql/authentication";

const OtpVerification: React.FC = () => {
  const { isLoggedIn: isOtpLoggedIn } = auth.useOtpAuthenticatioData();
  const [userOtp, setUserOtp] = React.useState<string>("");
  const [alertMessage, setAlertMessage] = React.useState<
    AlertProps | undefined
  >();

  if (!isOtpLoggedIn) {
    auth.otpLogOut();
  }

  const countdown = auth.useCountdown(300);

  const userEmail = auth.getEmailFromJWTToken();

  if (!userEmail) {
    auth.otpLogOut();
  }

  useEffect(() => {
    if (countdown === 0) {
      const timeout = setTimeout(() => {
        auth.otpLogOut();
      }, 100);
      return () => clearTimeout(timeout);
    }
  }, [countdown, auth]);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const [ValidateLoginOtp, { loading, error, data }] =
    useValidateLoginOtpLazyQuery();

  const closeAlertMessage = () => {
    setAlertMessage(undefined);
  };

  React.useEffect(() => {
    if (error) {
      setAlertMessage({ message: error.message, severity: "error" });
    }
    if (data) {
      auth.otpLogOut();
      auth.logIn(data.ValidateLoginOtp);
    }
  }, [error, data]);

  const handleUserOtp = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setUserOtp(value);
  };

  const handlerOnSubmit: React.FormEventHandler = React.useCallback(
    (event) => {
      event.preventDefault();
      setAlertMessage(undefined);
      const otpInt = parseInt(userOtp, 10);

      if (isNaN(otpInt)) {
        console.error("OTP must be a valid number");
        return;
      }

      ValidateLoginOtp({
        variables: {
          otp: otpInt,
          email: `${userEmail}`,
        },
      });
    },
    [userOtp, userEmail, ValidateLoginOtp],
  );

  const PageContent = (
    <SC.Box component="form" onSubmit={handlerOnSubmit}>
      <ProgressIndicator open={loading} />
      {alertMessage && (
        <SC.Alert
          elevation={0}
          icon={false}
          severity={alertMessage.severity}
          message={alertMessage.message}
          onClose={closeAlertMessage}
        />
      )}
      <SC.TexFieldBox>
        <TextField
          name="otp"
          label="OTP"
          value={userOtp}
          type="number"
          fullWidth
          required
          onChange={handleUserOtp}
        />
      </SC.TexFieldBox>
      <SC.HelpContainerBox>
        <SC.HelpBoxLeft>
          <SC.HelpText>Enter the OTP sent to your email address</SC.HelpText>
        </SC.HelpBoxLeft>
        <SC.HelpBoxLeft>
          <SC.HelpText>{formatTime(countdown)} Remaining</SC.HelpText>
        </SC.HelpBoxLeft>
      </SC.HelpContainerBox>
      <SC.ButtonContainer>
        <SC.Button color="primary" variant="contained" type="submit">
          Verify OTP
        </SC.Button>
      </SC.ButtonContainer>
    </SC.Box>
  );

  return <LandingPage>{PageContent}</LandingPage>;
};

export default OtpVerification;
