import packageJson from "../../package.json";

/**
 * Generates a persistent ID by appending the application version to the provided UUID.
 *
 * @param uuid - The unique identifier (UUID).
 * @returns The persistent ID as a string.
 * @throws Will throw an error if the UUID is invalid or empty.
 */
const generatePersistentId = (uuid: string): string => {
  if (!uuid || typeof uuid !== "string") {
    throw new Error("Invalid UUID: A non-empty string is required.");
  }

  const appVersion = packageJson.version;

  if (!appVersion || typeof appVersion !== "string") {
    throw new Error("Application version is not defined in package.json.");
  }

  return `${uuid}-${appVersion}`;
};

export default generatePersistentId;
