import React from "react";

import FormattedValue, {
  FORMAT_VALUE_OPTIONS,
} from "components/formatted-value/formatted-value";

// Styles
import SC from "./label-key-value.styles";

export interface LabelKeyValueProps {
  label: string;
  value: string;
  format?: keyof typeof FORMAT_VALUE_OPTIONS;
}

export const LabelKeyValue = ({
  label,
  format,
  value,
}: LabelKeyValueProps): JSX.Element => {
  return (
    <SC.Grid container spacing={2} style={{ marginBottom: 8 }}>
      <SC.Grid item xs>
        <SC.LabelText variant="body2">{label} </SC.LabelText>
      </SC.Grid>
      <SC.Grid item xs={6}>
        <SC.LabelValue variant="body2">
          <FormattedValue format={format ?? "string"} value={value} />
        </SC.LabelValue>
      </SC.Grid>
    </SC.Grid>
  );
};

export default LabelKeyValue;
