/* eslint-disable no-alert */
// Dependencies
import React, { useMemo } from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnArgs,
  Order_By,
  Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Select_Column,
  useGetPaginatedGraIndividualReturnQuery,
} from "graphql/hasura/types-and-hooks";

import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Components
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import Table, {
  TableFetchDataFunction,
  INITIAL_ROWS_PER_PAGE,
} from "components/table/table.component";
import {
  UniversalFilterResponse,
  ColumnsProps,
} from "components/universal-filter/universal-filter.component";
import Breadcrumb from "components/breadcrumb/breadcrumb.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import {
  FilterCondition,
  TableFilter,
} from "components/table-filter/table-filter.component";
import generatePersistentId from "utils/packageJson";

// Schema
import { tableColumns, getAccessorType } from "./individual-returns.schema";

// Assets
import SC from "./individual-returns.styles";

export const IndividualReturns: React.FC = () => {
  const [queryVariables, setQueryVariables] = React.useState<
    Omit<Query_RootRtcs_Gm_Dev_Fngra_IndividualReturnArgs, "args"> | undefined
  >();

  const [pageIndexResetSignal, setPageIndexResetSignal] = React.useState(false);

  const {
    data: responseData,
    loading,
    error,
  } = useGetPaginatedGraIndividualReturnQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !queryVariables,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
      aggregateParams: jsonToStringQueryVariables({
        where: queryVariables?.where,
      }),
    },
  });

  useAppErrorHandler(error);

  const commercialResidentialTaxData = React.useMemo(() => {
    const individual = responseData?.rtcs_gm_dev_Fngra_IndividualReturn ?? [];

    return individual;
  }, [responseData]);

  const columns = useMemo(() => tableColumns, []);

  const universalColumns: ColumnsProps[] = useMemo(() => {
    return columns.map((column, i) => {
      return {
        index: i,
        label: `${column.header}`,
        value: `${column.accessorKey}`,
        type: `${getAccessorType(column.accessorKey)}`,
      };
    });
  }, [columns]);

  const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
    const columnObject = condition[Object.keys(condition)[0]];
    if (columnObject[Object.keys(columnObject)[0]] === "") {
      // eslint-disable-next-line no-param-reassign
      condition = {};
    }
    setQueryVariables((oldQueryVariables) => ({
      ...oldQueryVariables,
      where: condition,
    }));
    setPageIndexResetSignal((previousSignal) => !previousSignal);
  };

  const handlePaginatedFetchData: TableFetchDataFunction = React.useCallback(
    ({ pageIndex, pageSize, sortBy }) => {
      const defaultSortColumn = {
        id: Rtcs_Gm_Dev_Ph_Gra_IndividualReturn_Select_Column.ReturnId,
        desc: true,
      };
      const sortByColumn = sortBy.length > 0 ? sortBy[0] : defaultSortColumn;
      setQueryVariables((oldVariables) => ({
        ...oldVariables,
        orderBy: {
          [sortByColumn.id]: sortByColumn.desc ? Order_By.Desc : Order_By.Asc,
        },
        limit: pageSize,
        offset: pageIndex * pageSize,
      }));
    },
    [],
  );

  const totalRowsCount = parseInt(
    responseData?.rtcs_gm_dev_Fngra_IndividualReturn_aggregatecm[0].value ??
      "-1",
    10,
  );

  const paginationControlled = React.useMemo(
    () => ({
      fetchData: handlePaginatedFetchData,
      loading,
      totalRowsCount,
    }),
    [totalRowsCount, loading, handlePaginatedFetchData],
  );

  const onTableFilterApply = React.useCallback(
    (conditions: FilterCondition[]) => {
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: {
          _and: conditions.length > 0 ? conditions : undefined,
        },
      }));
      setPageIndexResetSignal((previousSignal) => !previousSignal);
    },
    [],
  );

  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <ProgressIndicator open={loading} />
      <Table
        actionsOnRight={[
          "fullscreen-expand",
          "hide/show-columns",
          "filter-results",
        ]}
        title={"Individual Returns"}
        columns={columns}
        data={commercialResidentialTaxData}
        onAction={() => alert("under construction")}
        headerPanel={<SC.Title>MDA - Gambia Revenue Authority</SC.Title>}
        leftPanel={
          <TableFilter
            onFilterApply={onTableFilterApply}
            universalFilterColumns={universalColumns}
            onUniversalFilterSubmit={onUniversalFilterer}
          />
        }
        persistenceId={generatePersistentId(
          "9857f574-b997-4347-a541-7949b8701c86",
        )}
        paginationControlled={paginationControlled}
        stickyHeader
        initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
        pageIndexResetSignal={pageIndexResetSignal}
      />
    </SC.Box>
  );
};

export default IndividualReturns;
