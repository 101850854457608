/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import {
  Container as ContainerBase,
  ListSubheader as ListSubheaderBase,
  Box as BoxBase,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import SubHeader from "components/sub-header/sub-header.component";

const Container = styled(ContainerBase)(({ theme }) => ({
  overflowY: "scroll",
  padding: "0px",
  margin: "0px",
  paddingRight: "0px",
  marginRight: "0px",
  backgroundColor: theme.accordionBody.backgroundColor,
  height: "100vh",
  maxWidth: "none !important",
  paddingBottom: theme.propertiesTab.container.paddingBottom,
}));

const Box = styled(BoxBase)(() => ({
  height: "auto",
}));

const ListSubheader = styled(ListSubheaderBase)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  margin: "0px",
  padding: "0px",
  zIndex: 2,
}));

export default {
  Container,
  Breadcrumb,
  ListSubheader,
  SubHeader,
  Box,
};
