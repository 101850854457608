import { TableColumn } from "components/table/table.component";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import { GraIndividualReturnFragment } from "graphql/hasura/types-and-hooks";

export type IndividualReturnSchema = Omit<
  GraIndividualReturnFragment,
  "__typename"
>;

export const INDIVIDUAL_RETURNS_SCHEMA_MAP: Record<
  keyof IndividualReturnSchema,
  "string" | "number" | "Date"
> = {
  AccountingMethod: "string",
  Allowance: "number",
  Bonus: "number",
  BusinessActivity: "string",
  BusinessAddress: "string",
  BusinessNature: "string",
  CapitalAllowance: "number",
  ClosingStock: "number",
  CommercialRent: "number",
  Commission: "number",
  CostOfSale: "number",
  DirectTaxPaid: "number",
  EmployerAddress: "string",
  EmployerTIN: "number",
  EmployerTIN1: "string",
  EmployerTIN2: "string",
  ExemptIncome: "number",
  Exporter: "number",
  ForeignTaxCredit: "number",
  FringeBenefit: "number",
  FringeBenefitTax: "number",
  GrossEmolument: "number",
  Importer: "number",
  InstalmentPayment: "number",
  LossBroughtForward: "number",
  NetBusinessIncome: "number",
  OpeningStock: "number",
  Other: "number",
  OtherDeduction: "number",
  OtherExepense: "number",
  OtherIncome: "number",
  OverPayment: "number",
  Overtime: "number",
  Pension: "number",
  PositionHeld: "string",
  PrincipalBusiness: "string",
  Purchase: "number",
  ReturnGroup: "number",
  ReturnID: "string",
  SICExemption: "number",
  SalaryWages: "number",
  SubTotal: "number",
  SubTotal1: "number",
  Tax1stSchedule: "number",
  TotalNetIncome: "number",
  TurnOver: "number",
  TurnOverTax: "number",
  UnallowwableDeduction: "number",
  WithholdingTaxCredit: "number",
};

export const getAccessorType = (
  accessor:
    | keyof IndividualReturnSchema
    | AccessorFn<IndividualReturnSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return INDIVIDUAL_RETURNS_SCHEMA_MAP[accessor];
};

export const tableColumns: TableColumn<IndividualReturnSchema>[] = [
  {
    header: "Return ID",
    accessorKey: "ReturnID",
    align: "center",
    format: "string",
  },
  {
    header: "Position Held",
    accessorKey: "PositionHeld",
    align: "center",
    format: "string",
  },
  {
    header: "Business Nature",
    accessorKey: "BusinessNature",
    align: "center",
    format: "string",
  },
  {
    header: "Employer Address",
    accessorKey: "EmployerAddress",
    align: "left",
    format: "string",
  },
  {
    header: "Employer TIN",
    accessorKey: "EmployerTIN",
    align: "center",
    format: "string",
  },
  {
    header: "Business Address",
    accessorKey: "BusinessAddress",
    align: "left",
    format: "string",
  },
  {
    header: "Business Activity",
    accessorKey: "BusinessActivity",
    align: "center",
    format: "string",
  },
  {
    header: "Principal Business",
    accessorKey: "PrincipalBusiness",
    align: "center",
    format: "string",
  },
  {
    header: "Accounting Method",
    accessorKey: "AccountingMethod",
    align: "center",
    format: "string",
  },
  {
    header: "Salary Wages",
    accessorKey: "SalaryWages",
    align: "right",
    format: "number",
  },
  {
    header: "Allowance",
    accessorKey: "Allowance",
    align: "right",
    format: "number",
  },
  {
    header: "Fringe Benefit",
    accessorKey: "FringeBenefit",
    align: "right",
    format: "number",
  },
  {
    header: "Overtime",
    accessorKey: "Overtime",
    align: "right",
    format: "number",
  },
  {
    header: "Bonus",
    accessorKey: "Bonus",
    align: "right",
    format: "number",
  },
  {
    header: "Commission",
    accessorKey: "Commission",
    align: "right",
    format: "number",
  },
  {
    header: "Other",
    accessorKey: "Other",
    align: "right",
    format: "number",
  },
  {
    header: "Gross Emolument",
    accessorKey: "GrossEmolument",
    align: "right",
    format: "number",
  },
  {
    header: "Turn Over",
    accessorKey: "TurnOver",
    align: "right",
    format: "number",
  },
  {
    header: "Fringe Benefit Tax",
    accessorKey: "FringeBenefitTax",
    align: "right",
    format: "number",
  },
  {
    header: "Other Expense",
    accessorKey: "OtherExepense",
    align: "right",
    format: "number",
  },
  {
    header: "Net Business Income",
    accessorKey: "NetBusinessIncome",
    align: "right",
    format: "number",
  },
  {
    header: "Sub Total 1",
    accessorKey: "SubTotal1",
    align: "right",
    format: "number",
  },
  {
    header: "Direct Tax Paid",
    accessorKey: "DirectTaxPaid",
    align: "right",
    format: "number",
  },
  {
    header: "Pension",
    accessorKey: "Pension",
    align: "right",
    format: "number",
  },
  {
    header: "Commercial Rent",
    accessorKey: "CommercialRent",
    align: "right",
    format: "number",
  },
  {
    header: "Other Income",
    accessorKey: "OtherIncome",
    align: "right",
    format: "number",
  },
  {
    header: "Sub Total",
    accessorKey: "SubTotal",
    align: "right",
    format: "number",
  },
  {
    header: "Unallowable Deduction",
    accessorKey: "UnallowwableDeduction",
    align: "right",
    format: "number",
  },
  {
    header: "Exempt Income",
    accessorKey: "ExemptIncome",
    align: "right",
    format: "number",
  },
  {
    header: "Capital Allowance",
    accessorKey: "CapitalAllowance",
    align: "right",
    format: "number",
  },
  {
    header: "Other Deduction",
    accessorKey: "OtherDeduction",
    align: "right",
    format: "number",
  },
  {
    header: "Loss Brought Forward",
    accessorKey: "LossBroughtForward",
    align: "right",
    format: "number",
  },
  {
    header: "Tax 1st Schedule",
    accessorKey: "Tax1stSchedule",
    align: "right",
    format: "number",
  },
  {
    header: "Turn Over Tax",
    accessorKey: "TurnOverTax",
    align: "right",
    format: "number",
  },
  {
    header: "Over Payment",
    accessorKey: "OverPayment",
    align: "right",
    format: "number",
  },
  {
    header: "Foreign Tax Credit",
    accessorKey: "ForeignTaxCredit",
    align: "right",
    format: "number",
  },
  {
    header: "Withholding Tax Credit",
    accessorKey: "WithholdingTaxCredit",
    align: "right",
    format: "number",
  },
  {
    header: "Instalment Payment",
    accessorKey: "InstalmentPayment",
    align: "right",
    format: "number",
  },
  {
    header: "Importer",
    accessorKey: "Importer",
    align: "right",
    format: "number",
  },
  {
    header: "Exporter",
    accessorKey: "Exporter",
    align: "right",
    format: "number",
  },
  {
    header: "Employer TIN 1",
    accessorKey: "EmployerTIN1",
    align: "center",
    format: "string",
  },
  {
    header: "Employer TIN 2",
    accessorKey: "EmployerTIN2",
    align: "center",
    format: "string",
  },
  {
    header: "Return Group",
    accessorKey: "ReturnGroup",
    align: "right",
    format: "number",
  },
  {
    header: "SIC Exemption",
    accessorKey: "SICExemption",
    align: "right",
    format: "number",
  },
  {
    header: "Opening Stock",
    accessorKey: "OpeningStock",
    align: "right",
    format: "number",
  },
  {
    header: "Purchase",
    accessorKey: "Purchase",
    align: "right",
    format: "number",
  },
  {
    header: "Closing Stock",
    accessorKey: "ClosingStock",
    align: "right",
    format: "number",
  },
  {
    header: "Cost Of Sale",
    accessorKey: "CostOfSale",
    align: "right",
    format: "number",
  },
  {
    header: "Total Net Income",
    accessorKey: "TotalNetIncome",
    align: "right",
    format: "number",
  },
];
